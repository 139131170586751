import { httpServices } from '../../system/HttpServices/HttpService';
export default {
    getSubjects: function (data) {
        return httpServices.get({
            path: '/data/subjects',
            data: data,
            withCredentials: true,
            requestType: 'common',
        });
    },
    // FIXME Fix any and remove optional
    getProfileTypes: function (data) {
        return httpServices.get({
            path: '/data/ambassador/profileTypes',
            data: data,
            requestType: 'common',
        });
    },
    getCountries: function () {
        return httpServices.get({
            path: '/data/countries',
            requestType: 'common',
            rethrow: false,
        });
    },
    getAdmissionStages: function () {
        return httpServices.get({
            path: '/data/admissionStages',
            requestType: 'common',
            rethrow: false,
        });
    },
};
